@use '@/styles/utils/mixins.scss' as *;

.productCardWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.87rem;
  padding: 0 3.75rem;

  @include tab() {
    gap: 3.75rem;
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }

  @include mob() {
    margin-top: 5rem;
    width: calc(100% + 3.75rem);
    margin: 0 -1.875rem;
  }
  & > * {
    height: 100%;
  }
}

.productCardWrapperTwo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.875rem;
  padding: 0 3.75rem;

  @include tab() {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    gap: 3.75rem;
  }

  @include mob() {
    margin-top: 5rem;
    width: calc(100% + 3.75rem);
    margin: 0 -1.875rem;
  }
  & > * {
    height: 100%;
  }
}
